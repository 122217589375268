import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlSearchParams } from 'use-url-search-params';
import { Toaster } from 'react-hot-toast';
import { Tooltip, Whisper, Button } from 'rsuite';
import 'flatpickr/dist/themes/material_blue.css';
import 'react-drawer/lib/react-drawer.css';
import { Link, BrowserRouter } from 'react-router-dom';
import { fetchAllOrders } from '../actions/orderActions';
import android from '../../../assets/images/android.png';
import huawei from '../../../assets/images/huawei.png';
import apple from '../../../assets/images/apple.png';
import moment from 'moment';
import '../style.scss';
import DeliveryAlert from './DeliveryAlert';
import { Table, Col, Spinner, Badge, Label } from 'reactstrap';
import ExpressDelivery from './Shipping/ExpressDelivery';
import FilterSearch from '../../../components/FilterSearch/FilterSearch';
import DHPagination from '../../../components/Pagination';
import OrderDelete from './OrderDelete';
import AnalyticsTooltip from './analyticsTooltip';
const OrderList = (props) => {
  const location = useLocation();
  const search = useLocation().search;
  const paramsCountry = new URLSearchParams(search).get('cn');
  const dispatch = useDispatch();
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [isDataLoading, setDataLoading] = useState(false);
  const { country = [] } = useSelector((state) => state.country);
  let currentCountry = localStorage.getItem('WEBSITE_COUNTRY');
  let cntry = currentCountry;
  const { list = [], isLoading } = useSelector((state) => state.order);
  const { listOrders } = list;
  const orderData = listOrders?.orderList;
  const [orderEntityId, setOrderEntityId] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);

  let LsSts = 'New';

  const toggleDeletePopup = () => {
    setDeleteModal(!deleteModal);
  };

  const renderPaymentMethods = (paymentMode, order) => {
    let PayMode = '',
      paymentIcon = 'fa fa-credit-card';
    if (paymentMode === 'payfort') {
      PayMode = 'Payfort';
    } else if (paymentMode === 'payfort_fort_cc') {
      PayMode = 'Payfort';
    } else if (paymentMode === 'benefit') {
      PayMode = 'Benefit';
    } else if (paymentMode === 'postpay') {
      PayMode = 'Postpay';
    } else if (paymentMode === 'payfort_fort_installments') {
      PayMode = 'Payfort Installments';
    } else if (paymentMode === 'tamara') {
      PayMode = 'Tamara';
    } else if (paymentMode === 'tap') {
      PayMode = 'Tap';
    } else if (paymentMode === 'tap_careem_pay') {
      PayMode = 'Tap Careem Pay';
    } else if (paymentMode === 'tap_apple_pay') {
      PayMode = 'Tap Apple Pay';
    } else if (paymentMode === 'checkout') {
      PayMode = 'Checkout';
    } else if (paymentMode === 'spotiipay') {
      PayMode = 'Spotii';
    } else if (paymentMode === 'tap_knet') {
      PayMode = 'Tap Knet';
    } else if (paymentMode === 'apple_pay') {
      PayMode = 'Pay';
      paymentIcon = 'fab fa-apple';
    } else if (paymentMode === 'payfort_omannet') {
      PayMode = 'Payfort Omannet';
    } else if (paymentMode === 'cashew') {
      PayMode = 'Cashew';
    } else if (paymentMode === 'oman_net') {
      PayMode = 'Oman Net';
    } else if (paymentMode === 'tabby') {
      PayMode = 'Tabby';
    } else if (paymentMode === 'ifin') {
      PayMode = 'Ifin - Loan';
    } else if (paymentMode === 'synapse') {
      PayMode = 'Synapse - By Bank';
    } else if (paymentMode === 'cashondelivery') {
      PayMode = 'COD';
      paymentIcon = 'mdi mdi-cash-multiple';
    }
    console.log(PayMode, 'paymode');
    // if (paymentMode !== 'cashondelivery') {
    return (
      <Badge className="badge-soft-dark">
        <i className={`${paymentIcon}`}></i> {PayMode}
        {order?.wallet_amount > 0 ? (
          <>
            {' '}
            + <i className="fa fa-wallet"></i> Wallet
          </>
        ) : null}
        { }
      </Badge>
    );
    // } else {
    //   return <></>;
    // }
  };
  let userID = '';
  let UserRoleID = '';
  if (localStorage.getItem('authUser')) {
    const userObj = JSON.parse(localStorage.getItem('authUser'));
    if (userObj?.role_id !== undefined) {
      UserRoleID = userObj?.role_id;
      userID = userObj?.user_id;
    }
  }

  let pageIndex = Number(listOrders?.start);

  const [searchFilter, setSearchFilter] = useUrlSearchParams({ search: '' });
  const [statusFilter, setStatusFilter] = useUrlSearchParams({ status: 'All' });
  const [deviceFilter, setDeviceFilter] = useState('');
  const [paymentFilter, setPaymentFilter] = useState('');

  const [advancedFilter, setAdvancedFilter] = useState({});

  const [orderMode, setOrderMode] = useState('All');
  let is_split = 0;
  const splitVal = location?.pathname.split('/');
  useEffect(() => {
    if (splitVal[1] === 'splitorders') {
      is_split = 1;
      setOrderMode('split');
      localStorage.setItem('splitType', is_split);
    } else if (splitVal[1] === 'b2borders') {
      is_split = 2;
      setOrderMode('b2b');
      localStorage.setItem('splitType', is_split);
    } else if (splitVal[1] === 'storeorders') {
      is_split = 3;
      setOrderMode('store');
      localStorage.setItem('splitType', is_split);
    } else if (splitVal[1] === 'installorders') {
      is_split = 8;
      setOrderMode('b2b');
      localStorage.setItem('splitType', is_split);
    } else {
      is_split = 0;
      setOrderMode('All');
      localStorage.setItem('splitType', is_split);
    }
  }, [splitVal]);
  let splitType = JSON.parse(localStorage.getItem('splitType'));

  const getOrderList = useCallback(async (data) => {
    try {
      setDataLoading(true);
      await fetchAllOrders({
        params: data,
        dispatch,
      });
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setDataLoading(false);
    }
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      getOrderList({
        country: paramsCountry ? paramsCountry : cntry,
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: {
          value: '_date',
          order: 1,
        },
        search_qry: searchFilter?.search,
        status: statusFilter?.status,
        is_split,
        advancedFilter,
      });
    }, 300);
    return () => clearTimeout(delay);
  }, [
    paginationValue.page,
    limitValue.limit,
    searchFilter,
    statusFilter,
    advancedFilter,
    getOrderList,
  ]);

  return (
    <>
      <FilterSearch
        setAdvancedFilter={setAdvancedFilter}
        setSearchFilter={setSearchFilter}
        setStatusFilter={setStatusFilter}
      // setDeviceFilter={setDeviceFilter}
      // setPaymentFilter={setPaymentFilter}
      />
      <div className=" dt-table">
        <div className="table-responsive">
          <Table className="OrderListTable table mb-0">
            <thead>
              <tr>
                <th>SI.No</th>
                <th>Reference No</th>
                <th>Name</th>
                <th>Grand Total</th>
                <th>Status</th>
                <th>Device</th>
                <th></th>
              </tr>
            </thead>
            {isLoading || isDataLoading ? (
              <>
                <Spinner className="ms-2 sploader" color="primary" />
                <tbody>
                  {[...Array(2)].map((_, index) => (
                    <tr>
                      <td>
                        <p className="placeholder-glow">
                          <span className="placeholder col-2"></span>
                        </p>
                      </td>
                      <td>
                        <p className="placeholder-glow">
                          <span className="placeholder col-12"></span>
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow d-flex flex-column gap-1">
                          <span className="placeholder col-6"></span>
                          <span className="placeholder w-75"></span>
                          <span
                            className="placeholder"
                            style={{ width: '25%' }}
                          ></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                      <td>
                        {' '}
                        <p className="placeholder-glow">
                          <span className="placeholder col-6"></span>
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : orderData?.length > 0 ? (
              <>
                {(orderData || []).map((order, index) => {
                  const viewMoreBtn = `itv-${index}`;
                  pageIndex++;
                  const discountInfoData =
                    JSON.parse(order?.discount_info) || null;
                  const groupedDiscounts = (discountInfoData?.details || []).reduce(
                    (acc, item) => {
                      const type = item?.ruleType;
                      acc[type] =
                        (acc[type] || 0) + item?.totalDiscountAppliedAmount;
                      return acc;
                    },
                    {}
                  );

                  // Format output as "RuleType: DiscountAmount"
                  const formattedDiscounts = Object.entries(groupedDiscounts)
                    .map(([type, amount]) => `${type}: ${amount.toFixed(2)}`)
                    .join(', ');

                  let deliveryDt = order?.delivery_dt
                    ? order?.delivery_dt
                    : null;

                  // Or using the conditional operator in a more concise way:

                  let order_id = order?.entity_id;

                  let split = 'All';
                  let orderCn = order?.country.toLowerCase();
                  const orderSplits = order?.reference_no?.slice(0, 2);
                  if (
                    (orderMode === 'split' && country === 'ae') ||
                    orderSplits === 'WH' ||
                    orderSplits === 'CR' ||
                    orderSplits === 'ST'
                  ) {
                    split = 'split';
                    order_id = order?.order_id;
                    orderCn = 'ae';
                  } else if (
                    (orderMode === 'b2b' && country === 'ae') ||
                    orderSplits === 'BB'
                  ) {
                    split = 'b2b';
                    order_id = order?.order_id;
                    orderCn = 'ae';
                  } else if (
                    orderMode === 'store' &&
                    (country === 'ae' || orderCn === 'ae')
                  ) {
                    split = 'store';
                    order_id = order?.entity_id;
                    orderCn = 'ae';
                  } else if (
                    orderMode === 'store' &&
                    country !== 'ae' &&
                    orderCn !== 'ae'
                  ) {
                    split = 'All';
                    order_id = order?.entity_id;
                  }
                  let cdate = order?.created_at,
                    orderDt = moment(new Date(cdate)).format('Do MMM, YYYY'),
                    orderTime = moment(new Date(cdate)).format('h:mm:ss a');

                  let stsBtn = 'bg-soft-dark text-dark';
                  let lsOrderSts = '';
                  if (!!order.ls_status) {
                    lsOrderSts = order?.ls_status.toLowerCase().trim();
                  } else {
                    lsOrderSts = order?.ls_status;
                  }

                  const webOrderSts = order?.status.toLowerCase().trim();
                  if (
                    lsOrderSts === '0' ||
                    lsOrderSts === '' ||
                    lsOrderSts === null
                  ) {
                    if (webOrderSts === 'canceled' || lsOrderSts === 'cancel') {
                      LsSts = 'Canceled';
                      stsBtn = 'bg-soft-danger text-danger';
                    } else if (webOrderSts === 'pending_payment') {
                      LsSts = 'Pending Payment';
                      stsBtn = 'bg-soft-danger text-danger';
                    } else if (webOrderSts === 'pending') {
                      LsSts = 'Pending';
                      stsBtn = 'bg-soft-warning text-dark';
                    } else {
                      LsSts = 'New';
                      stsBtn = 'bg-soft-info text-dark';
                    }
                  } else if (
                    splitType === 3 &&
                    lsOrderSts === 'material_received'
                  ) {
                    LsSts = 'Material Received';
                    stsBtn = 'bg-soft-warning text-dark';
                  } else if (
                    webOrderSts === 'canceled' ||
                    lsOrderSts === 'cancel'
                  ) {
                    LsSts = 'Canceled';
                    stsBtn = 'bg-soft-danger text-danger';
                  } else if (lsOrderSts === 'open') {
                    stsBtn = 'bg-soft-warning text-dark';
                    LsSts = 'Pending';
                  } else if (lsOrderSts === 'accepted') {
                    stsBtn = 'bg-soft-dark text-dark';
                    LsSts = 'Accepted';
                  } else if (lsOrderSts === 'confirmed') {
                    stsBtn = 'bg-soft-dark text-dark';
                    LsSts = 'Confirmed';
                  } else if (lsOrderSts === 'posted') {
                    stsBtn = 'bg-soft-info text-dark';
                    LsSts = 'Posted';
                  } else if (lsOrderSts === 'material n/a') {
                    stsBtn = 'bg-soft-warning text-dark';
                    LsSts = 'Material N/A';
                  } else if (lsOrderSts === 'delivered') {
                    stsBtn = 'bg-soft-success text-dark';
                    LsSts = 'Delivered';
                  } else if (lsOrderSts === 'completed') {
                    stsBtn = 'bg-soft-success text-dark';
                    LsSts = 'Completed';
                  } else if (lsOrderSts === 'picked') {
                    stsBtn = 'bg-soft-warning text-dark';
                    LsSts = 'Picked';
                  } else if (lsOrderSts === 'delivery_scheduled') {
                    stsBtn = 'bg-soft-success text-dark';
                    LsSts = 'Delivery Scheduled';
                  } else if (lsOrderSts === 'rescheduled') {
                    stsBtn = 'bg-soft-success text-dark';
                    LsSts = 'Rescheduled';
                  } else if (lsOrderSts === 'partially_delivered') {
                    stsBtn = 'bg-soft-warning text-dark';
                    LsSts = 'Partially Delivered';
                  } else if (
                    lsOrderSts === 'out_for_delivery' ||
                    lsOrderSts === 'assigned'
                  ) {
                    stsBtn = 'bg-soft-success text-dark';
                    LsSts = 'Out For Delivery';
                  } else if (lsOrderSts === 'packed') {
                    LsSts = 'Packed';
                    stsBtn = 'bg-soft-warning text-dark';
                  } else {
                    LsSts = 'New';
                    stsBtn = 'bg-soft-dark text-dark';
                  }

                  let paymentMode = '';
                  paymentMode = order?.payment_mode;

                  let device_data = '';
                  let deviceArray = '';
                  let deviceVersion = '';
                  let osData = '';
                  let device_Version = '';
                  let deviceTypeValue = '';

                  if (order?.device !== null || order?.device !== undefined) {
                    let deviceData = order?.device_tracking;
                    deviceArray = deviceData?.split('||');
                    deviceTypeValue = deviceArray?.reduce((value, item) => {
                      if (item?.startsWith('deviceType:')) {
                        value = item?.split(':')[1].trim();
                      }
                      return value;
                    }, null);

                    if (order?.os === 'android' || order?.os === 'ios') {
                      deviceVersion = deviceArray[9]?.split(':');

                      device_data =
                        deviceTypeValue === 'huawei'
                          ? deviceTypeValue
                          : order?.os;
                      device_Version = deviceVersion?.[1];
                    } else {
                      if (deviceData && deviceData !== undefined) {
                        if (deviceArray && deviceArray?.length > 0) {
                          if (deviceArray[9]) {
                            deviceVersion = deviceArray[9]?.split(':');
                            device_Version = deviceVersion?.[1];
                          }
                        }
                      }

                      device_data = '';
                    }
                  }
                  let isWeb = '';
                  var installdata = '';
                  let deData = '';
                  if (order.device === 'phone') {
                    let appData = order?.device_tracking;
                    const appArray = appData.split('||');
                    if (typeof appArray[12] !== 'undefined') {
                      const appVersion = appArray[12]?.slice(
                        appArray[12].indexOf(':') + 1
                      ); //appArray[12].split(":");
                      if (
                        typeof appVersion !== 'undefined' &&
                        appVersion != ''
                      ) {
                        const objInstall = JSON.parse(appVersion);
                        if (
                          typeof objInstall?.number_of_installment !==
                          'undefined'
                        ) {
                          const percentInstall =
                            objInstall?.fee_display_value / 100;
                          installdata =
                            objInstall?.number_of_installment +
                            'Month EMI' +
                            ' ' +
                            objInstall?.amountPerMonth +
                            '/Month';
                        }
                      }
                    }
                  } else {
                    if (
                      order.device === '' ||
                      order.device === 'web' ||
                      order.device === 'Desktop' ||
                      order.device === 'Mobile'
                    ) {
                      if (deviceVersion === 'web') {
                        deData = 'mdi mdi-laptop-windows';
                      }
                      if (deviceVersion === 'Desktop') {
                        deData = 'mdi mdi-laptop-windows';
                      }
                      if (deviceVersion === 'Mobile') {
                        deData = 'mdi mdi-laptop-windows';
                      }

                      if (order.os === 'Windows') {
                        osData = 'mdi mdi-microsoft-windows';
                      } else if (order.os === 'Mac OS') {
                        osData = 'mdi mdi-apple';
                      } else if (order.os === 'Linux') {
                        osData = 'mdi mdi-linux';
                      } else {
                        osData = 'mdi mdi-web';
                      }

                      isWeb = 'Web';
                      if (typeof order.device_tracking !== 'undefined') {
                        const appVersion = order.device_tracking.slice(
                          order.device_tracking.indexOf(':') + 1
                        );
                        if (
                          typeof appVersion !== 'undefined' &&
                          appVersion != ''
                        ) {
                          try {
                            const objInstall = JSON.parse(appVersion);
                            if (
                              typeof objInstall.number_of_installment !==
                              'undefined'
                            ) {
                              installdata =
                                objInstall.number_of_installment +
                                ' Month Installment';
                            }
                          } catch (e) {
                            installdata = '';
                          }
                        }
                      }
                    }
                  }

                  const storeInfo = order?.store_order_info
                    ? JSON.parse(order.store_order_info)
                    : null;
                  if (storeInfo?.staffId) {
                    deData = 'mdi mdi-cellphone-screenshot';
                    isWeb = 'Kiosk';
                  } else if (device_Version === 'web') {
                    deData = 'mdi mdi-laptop-windows';
                  } else if (device_Version === 'Desktop') {
                    deData = 'mdi mdi-laptop-windows';
                  } else if (device_Version === 'Mobile') {
                    if (order?.os.toLowerCase() === 'android') {
                      deData = 'mdi mdi-cellphone';
                      osData = 'mdi mdi-android-debug-bridge';
                    } else if (order?.os.toLowerCase() === 'ios') {
                      deData = 'mdi mdi-cellphone';
                      osData = 'mdi mdi-apple';
                    } else {
                      deData = 'mdi mdi-laptop-windows';
                    }
                  }
                  let installemntPayment = '';
                  let appDataPayment = '';
                  let installemnt_months = '';
                  if (order?.is_installment === '1') {
                    appDataPayment = order?.payment_info;
                    installemntPayment = JSON?.parse(appDataPayment);
                    const installment_details =
                      installemntPayment?.installment_details;
                    if (installment_details !== undefined) {
                      if (Object.keys(installment_details).length > 0) {
                        installemnt_months =
                          installemntPayment?.installment_details
                            ?.number_of_installment +
                          ' Month EMI ' +
                          ' ' +
                          installemntPayment?.installment_details
                            ?.amountPerMonth +
                          '/Month';
                      }
                    } else if (installemntPayment?.additionalInfo) {
                      installemnt_months =
                        installemntPayment?.additionalInfo
                          ?.number_of_installment +
                        ' Month EMI ' +
                        ' ' +
                        installemntPayment?.additionalInfo?.amountPerMonth +
                        '/Month';
                    }
                  }

                  const createdDateObj = moment(
                    new Date(order.created_at)
                  ).startOf('day');

                  const deliveryDateObj = moment(
                    new Date(order?.delivery_dt)
                  ).startOf('day');
                  const daysDifference = deliveryDateObj.diff(
                    createdDateObj,
                    'days'
                  );
                  let badgeColor = '';
                  if (daysDifference > 9) {
                    badgeColor = 'bg-soft-danger text-danger';
                  } else {
                    badgeColor = 'bg-soft-warning text-warning';
                  }

                  let paymentCls = '';
                  if (
                    order?.financial_status === '0' &&
                    order?.payment_mode !== 'cashondelivery' &&
                    (order?.ls_status !== 'Cancel' ||
                      order?.status !== 'cancel') &&
                    order?.status !== 'canceled'
                  ) {
                    paymentCls = '';
                  }

                  return (
                    <tbody key={order?.order_id} className={`${paymentCls}`}>
                      <tr>
                        <th scope="row">{pageIndex} </th>

                        <td>
                          <p className="m-0 d-flex gap-1">
                            <strong>{order?.reference_no} </strong>
                            <span className="text-capitalize d-flex gap-1">
                              {renderPaymentMethods(paymentMode, order)}
                              <ExpressDelivery
                                shippingAmt={order?.express_shipping_amt}
                                width={'25px'}
                              />
                              {/* {order?.express_shipping_amt > "0.00" ? (
                          <>
                            <span><img src={expresslogo} style={{width:'25px'}}></img></span>
                          </>
                          ) : null}  */}
                            </span>
                            {order?.advData?.length > 0 ? (
                              <AnalyticsTooltip anlyticData={order?.advData} />
                            ) : null}
                          </p>

                          {installdata ? (
                            <>
                              {' '}
                              <div>
                                <span className="installPlan">
                                  {installdata}
                                </span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {installemnt_months ? (
                            <>
                              <div>
                                <span className="installPlan">
                                  {installemnt_months}{' '}
                                </span>
                              </div>
                            </>
                          ) : null}

                          <p className="m-0 fs-11">
                            {orderDt} {orderTime}
                          </p>

                          {splitVal[1] === 'storeorders' ? (
                            <Badge className=" bg-soft-info text-info mt-1 me-1">
                              Click and Collect
                            </Badge>
                          ) : null}

                          {order?.ls_reference ? (
                            <p className="fs-11 m-0">
                              LS Ref : <b>{order?.ls_reference}</b>
                            </p>
                          ) : null}
                        </td>

                        <td>
                          {order?.full_name}{' '}
                          {order?.is_guest && Number(order?.is_guest) === 0 ? (
                            <i
                              className="mdi mdi-account-check  text-success"
                              title="SigIn"
                            ></i>
                          ) : null}
                          <span className="noTxtWrap" title={order?.region}>
                            {order?.country}-{order?.region}
                          </span>
                          {order?.zoneId ? (
                            <span className="noTxtWrap">
                              <b
                                className="border border-warning bg-soft-warning rounded px-1 py-0 fs-11"
                                title="zoneId"
                              >
                                <i className="mdi mdi-map-marker-multiple"></i>{' '}
                                {' - '}
                                {order?.zoneId}
                              </b>
                            </span>
                          ) : null}
                        </td>

                        <td>
                          {order?.currency} {order?.grand_total}
                          {order.wallet_amount > 0 ? (
                            <p>
                              <Badge className="badge-soft-dark">
                                Wallet Reedemed - {order?.currency}{' '}
                                {order.wallet_amount}
                              </Badge>
                            </p>
                          ) : (
                            ''
                          )}
                          {order?.discount_amt > 0 ? (
                            <>
                              <div className="highlightInfo1">
                                <p className="mb-0">
                                  Discount:{' '}
                                  <b>
                                    {splitVal[1] === 'splitorders' ||
                                      splitVal[1] === 'b2borders' ||
                                      splitVal[1] === 'storeorders'
                                      ? `${order?.coupon_code} : `
                                      : null}{' '}
                                    {order?.discount_amt}
                                  </b>
                                </p>

                                {order?.discount_amt > 0 &&
                                  splitVal[1] !== 'splitorders' &&
                                  splitVal[1] !== 'b2borders' ? (
                                  <b>{formattedDiscounts}</b>
                                ) : null}
                              </div>
                            </>
                          ) : null}
                          {order?.shipping_amt > '0.00' ? (
                            <>
                              <div className="highlightInfo2">
                                Shipping Fee: {order?.shipping_amt} <br></br>
                              </div>
                            </>
                          ) : null}
                          {order?.express_shipping_amt > '0.00' ? (
                            <>
                              <span className="expressBx">
                                Express Fee :{order?.express_shipping_amt}
                              </span>
                            </>
                          ) : null}
                        </td>
                        <td>
                          {splitVal[1] !== 'splitorders' &&
                            splitVal[1] !== 'b2borders' &&
                            splitVal[1] !== 'storeorders' &&
                            order.country.toLowerCase() === 'ae' ? (
                            <>
                              {order?.financial_status === '0' &&
                                order?.payment_mode !== 'cashondelivery' &&
                                (order?.ls_status !== 'Cancel' ||
                                  order?.status !== 'cancel') &&
                                order?.status !== 'canceled' ? (
                                <>
                                  {' '}
                                  <Badge className="bg-soft-danger text-danger badge">
                                    Payment Failed
                                  </Badge>
                                  {order?.payment_failed_reason !==
                                    undefined ? (
                                    <>
                                      {order?.payment_failed_reason !== null &&
                                        order?.payment_failed_reason !== '' ? (
                                        <>
                                          <div className="ReturnInfo1">
                                            <div className="highlightInfo5">
                                              Reason:{' '}
                                              {order?.payment_failed_reason}
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {LsSts === 'Canceled' ||
                                    LsSts === 'Payment Failed' ? (
                                    <>
                                      {' '}
                                      <Badge className={` ${stsBtn}`}>
                                        {LsSts}
                                      </Badge>
                                    </>
                                  ) : (
                                    <> </>
                                  )}{' '}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {order?.financial_status === '0' &&
                                order?.payment_mode !== 'cashondelivery' &&
                                (order?.ls_status !== 'Cancel' ||
                                  order?.ls_status !== 'cancel') &&
                                order?.status !== 'canceled' ? (
                                <>
                                  {' '}
                                  <Badge className=" bg-soft-danger text-danger">
                                    Payment Failed
                                  </Badge>
                                  {order?.payment_failed_reason !==
                                    undefined ? (
                                    <>
                                      {order?.payment_failed_reason !== null &&
                                        order?.payment_failed_reason !== '' ? (
                                        <>
                                          <div className="ReturnInfo1">
                                            <div className="highlightInfo5">
                                              Reason:{' '}
                                              {order?.payment_failed_reason}
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {' '}
                                  <Badge className={` ${stsBtn} me-2`}>
                                    {LsSts}
                                  </Badge>
                                </>
                              )}
                            </>
                          )}
                          {order?.delivery_dt ? (
                            <>
                              <span className="shipmentInfoBx">
                                Date : {order?.delivery_dt}
                                {order?.is_owndt === '1' ? (
                                  <>
                                    {' '}
                                    <span
                                      className="border border-danger rounded-pill p-0 d-inline-flex align-items-center justify-content-center"
                                      style={{ width: 14, height: 14 }}
                                    >
                                      <i className="fas fa-user fs-8"></i>
                                    </span>
                                  </>
                                ) : null}
                              </span>
                              <span className="shipmentDaysInfo">
                                No.of Days:{' '}
                                <Badge
                                  className={` ${badgeColor} text-dark rounded-pill`}
                                >
                                  {Math.abs(daysDifference)}
                                </Badge>{' '}
                              </span>
                            </>
                          ) : null}
                          {order?.delivery_time_slot ? (
                            <span className="slotBx">
                              Slot :{order?.delivery_time_slot}
                            </span>
                          ) : null}
                        </td>
                        <td>
                          {isWeb !== '' ? (
                            <>
                              <div className="d-flex gap-1">
                                {order?.oms_user_id && (
                                  <>
                                    {' '}
                                    <Badge className="badge-soft-dark text-primary">
                                      OMS
                                    </Badge>
                                  </>
                                )}
                                <Badge className="badge-soft-dark">
                                  {isWeb}
                                </Badge>

                                <i className={osData}></i>
                                <i className={deData}></i>
                              </div>
                            </>
                          ) : null}
                          {device_data === 'huawei' ? (
                            <>
                              <Badge className="badge-soft-dark">
                                App <span className="highlightInfo1"></span>
                              </Badge>{' '}
                              <img src={huawei} alt="" height="15" />
                            </>
                          ) : null}
                          {device_data === 'android' ? (
                            <>
                              <Badge className="badge-soft-dark">
                                App <span className="highlightInfo1"></span>
                              </Badge>{' '}
                              <img src={android} alt="" height="15" />
                            </>
                          ) : null}
                          {device_data === 'ios' ? (
                            <>
                              <Badge className="badge-soft-dark">App</Badge>{' '}
                              <img src={apple} alt="" height="15" />
                            </>
                          ) : null}
                          <div className="version">
                            <Badge className="badge-soft-dark">
                              {deviceVersion?.[0] === 'AppVersion' ? (
                                <> V- {deviceVersion?.[1]}</>
                              ) : null}
                            </Badge>
                          </div>
                          {storeInfo?.staffId ? (
                            <p className="fs-11">
                              {storeInfo?.storeCode} - {storeInfo?.staffId}
                            </p>
                          ) : null}{' '}
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <BrowserRouter forceRefresh={true}>
                              <Link
                                to={`/orders/${order_id}/?order_mode=${split}&orderCn=${orderCn}&showPopup=false`}
                                className="btn btn-sm btn-outline-warning waves-effect waves-light"
                                target="_blank"
                              >
                                <i className="mdi mdi-eye-outline"></i>{' '}
                              </Link>
                            </BrowserRouter>
                            {UserRoleID === '1' ? (
                              <>
                                <a
                                  href={`OrderLogReport/${order.reference_no}`}
                                  type="button"
                                  className="btn btn-sm btn-outline-secondary waves-effect waves-light"
                                >
                                  <i className="mdi mdi-information-outline"></i>{' '}
                                </a>
                              </>
                            ) : null}

                            {order?.financial_status === '0' &&
                              UserRoleID === '1' ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-danger waves-effect waves-light"
                                  onClick={() => {
                                    setOrderEntityId(order?.entity_id);
                                    toggleDeletePopup();
                                  }}
                                >
                                  <i className="mdi mdi-delete"></i>{' '}
                                </button>
                              </>
                            ) : null}

                            <DeliveryAlert
                              key={`${pageIndex}-${order_id}`}
                              deliveryDt={deliveryDt}
                              status={LsSts}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        {orderData?.length > 0 ? (
          <DHPagination
            totalPages={listOrders?.recordsTotal}
            currentPage={paginationValue.page}
            setPaginationValue={setPaginationValue}
            setLimitValue={setLimitValue}
          />
        ) : null}
      </div>

      <Toaster position="top-right" reverseOrder={false} />
      <OrderDelete
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        userID={userID}
        entityId={orderEntityId}
      />
    </>
  );
};

export default OrderList;
